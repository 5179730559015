import images from "./images";

const specials = [
  {
    photo: images.img7,
    name: "Pizza",
    description:
      "Hot and spicy pizza made fresh.Hot and spicy pizza made fresh.Hot and spicy pizza made fresh.Hot and spicy pizza made fresh.",
  },
  {
    photo: images.img1,
    name: "Roll",
    description:
      "Lorem ipsum dolor sit amet, consectetur.Lorem ipsum dolor sit amet, consectetur.Lorem ipsum dolor sit amet, consectetur.Lorem ipsum dolor sit amet, consectetur.Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    photo: images.img5,
    name: "Drink",
    description:
      "Lorem ipsum dolor sit amet, consectetur.Hot and spicy pizza made fresh.Hot and spicy pizza made fresh.Lorem ipsum dolor sit amet, consectetur.Hot and spicy pizza made fresh.Hot and spicy pizza made fresh.",
  },
  {
    photo: images.img6,
    name: "Bowl",
    description: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

export default specials;
